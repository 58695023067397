const roomInfo= {
    158445: 'Crosland 6111',
    158446: 'Crosland 6117',
    158447: 'Crosland 6157',
    158448: 'Crosland 2111',
    158450: 'Crosland 2117',
    158459: 'Crosland 2151',
    158460: 'Crosland 2157',
    158485: 'Crosland 4111',
    158486: 'Crosland 4117',
    158497: 'Price Gilbert 2202',
    158499: 'Price Gilbert 2216',
    158500: 'Price Gilbert 2217',
    158507: 'Price Gilbert G202',
    158508: 'Price Gilbert G292',
    158659: 'Price Gilbert G194',
    158661: 'Price Gilbert G266',
    158662: 'Price Gilbert G268',
    158668: 'Clough 242',
    158669: 'Clough 244',
    158670: 'Clough 246',
    158671: 'Clough 252',
    158673: 'Clough 315',
    158674: 'Clough 317',
    158675: 'Clough 342',
    158676: 'Clough 344',
    158678: 'Clough 421',
    158679: 'Clough 455',
    158680: 'Clough 572A',
    158698: 'Clough 572B',
    158699: 'Clough 572C',
    158700: 'Clough 572D',
    158701: 'Clough 585',
    160969: 'Clough 445',
    166932: 'Price Gilbert G192'
}

export default roomInfo;